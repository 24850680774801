import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './pages/about/about.component';
import { CoursesComponent } from './pages/courses/courses.component';
import { HomeComponent } from './pages/home/home.component';
import { NewsComponent } from './pages/news/news.component';
import { LandingComponent } from './fevertree/landing/landing.component';
import { MaintenancePlannerComponent} from './fevertree/maintenance-planner/maintenance-planner.component';


const routes: Routes = [
  {path: '', component : LandingComponent},
  {path: 'home', component : HomeComponent},
  {path: 'courses', component : CoursesComponent},
  {path: 'about', component : AboutComponent},
  {path: 'news', component : NewsComponent},
  {path: 'news/:id', component : NewsComponent},
  {path: 'maintenace-planner', component : MaintenancePlannerComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
