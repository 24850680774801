import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  private baseUrl = environment.baseUrlBlog;
  private apiKey = environment.apiKey;
  private headers = new HttpHeaders({ 'Authorization': this.apiKey });
  private organizationId = environment.organisationId
  public articles: BehaviorSubject<any>;
  constructor(private http: HttpClient) {
    this.articles = new BehaviorSubject([]);
  }

  setArticles(articles) {
    this.articles.next(articles)
  }

  getArticles() {
    return this.articles.asObservable();
  }

  getArticleByID(id) {
    return this.http.get<any>(`${this.baseUrl}blog/${id}`, {
      headers: this.headers,
      params: new HttpParams().set('getArticleBody', "true")
    }).toPromise();
  }


  getBlogsListByOrgId(): Promise<any> {

    return this.http.get<any>(`${this.baseUrl}blog`, {
      headers: this.headers,
      params: new HttpParams().set('getArticleBody', "false").set('organizationId', this.organizationId)
    }).toPromise();
  }

  getBlogsBodyByOrgId(articles): Promise<any> {
    let query = {};

    articles.forEach(element => {
      query[element.blogId] = this.http.get<any>(`${this.baseUrl}blog/${element.blogId}`, {
        headers: this.headers,
      }).toPromise();
    });

    return forkJoin(query).toPromise();
  }

}
