import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BlogService } from 'src/app/services/blog/blog.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewsComponent implements OnInit {

  blogs = [];
  links = [];
  loading = true;
  constructor(private blogSrvc: BlogService) {
    this.loading = true;
    this.blogSrvc.getBlogsListByOrgId().then(
      resp => {
        if (resp.blogs.length > 0) {
          this.links = resp.blogs;
          this.fetchBodies(resp.blogs);
          return
        }
        this.loading = false
      }
    ).catch(
      error => {
        console.log(error);
        this.loading = false
      }
    )
  }

  fetchBodies(blogs) {
    this.blogSrvc.getBlogsBodyByOrgId(blogs).then(
      resp => {
        console.log(resp);
        
        this.blogs = resp;
        this.activeArticle = this.blogs[Object.keys(this.blogs)[0]];
        console.log(this.blogs);
       //console.log( this.objToArray( this.blogs));
       
        this.loading = false;
      }
    ).catch(
      error => {
        console.log(error);

      }
    )
  }

  
  activeArticle = null;

  viewArticle(article) {

    window.scrollTo({
      top: 0,
    });
    this.loading = true;
    console.log(this.blogs[article.blogId]);
    
    this.activeArticle = this.blogs[article.blogId];
    this.loading = false;

  }


  ngOnInit(): void {
    window.scrollTo({
      top: 0,
    });

  }
}
