<header #hero>

<div class="max-wrapper">
  <div id="logo"></div>
  <button id="menu" (click)="openMenu()">
    <span class="material-icons">
      {{isMenuOpened ? "close" : "menu"}}
    </span>
  </button>
  <div id="mobile-menu" [ngClass]="{'opened-menu': isMenuOpened}">
    <div><a>Home</a></div>
    <div routerLink="/maintenace-planner"><a>Maintenance Planner</a></div>
  </div>

  <nav>
    <ul>
      <ul>
        <li routerLink="/"><a>Home</a></li>
        <li routerLink="/maintenace-planner"><a>Maintenance Planner</a></li>
      </ul>
    </ul>

  </nav>
</div>
</header>

<section>
  <div id="banner">

    <div class="wrapper">
      <div id="vertical-slides-musk">
        <div id="vertical-slides" >
          <div class="slide">
            <div class="row">
              <div class="col-1">
                <div class="heading">
                  Fund <span>Your</span> Short Course Through FeverTree
                </div>
                <!-- <div class="call-to-actions">
                  <button routerLink="/about">Learn more <span class="material-icons">
                      east
                    </span></button>
                </div> -->
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

  </div>
</section>

<main>
  <div class="wrapper">

    <div id="values">
      <div id="">
        <!-- <div class="card" >
          <img class="logos" id="tut-logo" src="https://tuteh-storage.s3-eu-west-1.amazonaws.com/assets/tut_logo.png">
        </div> -->
        <p class="logo">
          <img width="350px" src="https://tuteh-storage.s3-eu-west-1.amazonaws.com/assets/fevertree.png">
        </p>
      </div>
    </div>

    <div id="operations">
        <div id="fake-about" class="two-col wrapper">

          <div class="content">
            <!-- <div class="heading">
              Fund your online course over 12 months
            </div> -->
            <!-- <div class="sub-heading">Tshwane Institute for Continuing Education</div> -->
            <p style="font-size: 25px;">
              TICE has partnered with FeverTree Finance to offer you convenient way to fund your short courses.
            </p>
            <ul>
              <li><span>&#9737;</span> How do I apply?<br>
                Email the required documents to enquiries@fevertreefinance.co.za: colour copy of your ID and last three months’ bank statements. <br> Or <br> Click on this link <a href="https://www.ftapp.co.za/app/TI?source=TIOnline"> https://www.ftapp.co.za/app/TI?source=TIOnline </a>
                to complete your online application and upload the required documents.
              </li>
              <li><span>&#9737;</span> Once your application is processed and necessary checks are done, a letter of approval will be shared with TICE for registration confirmation.</li>
            </ul>
            <!-- <button mat-raised-button color="accent">Download Brochure here</button> -->
          </div>

          <div id="colloboraters">
            <iframe id="inlineFrameExample" width="100%" height="700px" title="Inline Frame Example" src="https://www.ftapp.co.za/app/TI?source=TIOnline">
            </iframe>
          </div>
        </div>

        <div class="wrapper">
          <div class="content">
            <div class="heading">
              Terms & Conditions
            </div>
            <ul>
              <li><span>&#9737;</span> Operated by FeverTree Finance (Pty) Ltd, a registered credit provider (NCRCP6072).</li>
              <li><span>&#9737;</span> You have 12 months to settle your account.</li>
              <li><span>&#9737;</span> You need to be between the ages of 18 and 65, earn a minimum of R5 000 per month and be a responsible credit user.</li>
              <li><span>&#9737;</span> We may contact you to obtain your latest payslip.</li>
              <li><span>&#9737;</span> Subject to affordability.</li>
              <li><span>&#9737;</span> SA citizens only.</li>
              <li><span>&#9737;</span> Your Account Agreement includes FeverTree Credit Life Insurance, underwritten by Guardrisk Life Limited, a licensed life insurer and authorised financial services provider (FSP76).</li>
            </ul>
          </div>
        </div>
    </div>

    <div id="operations">
      <div class="wrapper">
        <div class="content">
          <div class="heading">
            FAQs
          </div>
          <div class="sub-heading">
            Frequently Asked Questions.
          </div>
          <ul>
            <li><span>&#9737;</span> <b>What is the minimum qualifying criteria for an account?</b><br>
              The customer needs to be between the ages of 18 and 65, earn a minimum of R5 000 a month and be a responsible credit user (we use
              their credit record). SA citizens only
            </li><br>
            <li><span>&#9737;</span> <b> What documentation is required from the customer? </b><br>
              A colour copy of their ID document/drivers’ licence, latest three months’ bank statements
            </li><br>
            <li><span>&#9737;</span> <b>What if the customer doesn’t qualify/what if their application is unsuccessful?</b><br>
              FeverTree can provide detailed feedback directly to the customer on the reason.
            </li><br>
            <li><span>&#9737;</span> <b>How does the customer pay for their account?</b><br>
              The customer will make payments via debit order directly to FeverTree Finance.
            </li>
          </ul>
        </div>

        <!-- <div class="img">
          <img src="https://tuteh-storage.s3-eu-west-1.amazonaws.com/assets/faqs.png" alt="">
        </div> -->
      </div>
    </div>
    <br><br>

  </div>
</main>

<footer>
  <div class="wrapper">
    <div class="col-4">
      <div>
        <h3>Links</h3>
        <a routerLink=""><span>Home</span></a>
        <a routerLink="/maintenace-planner"><span>Maintenance Planner</span></a>
      </div>
      <div>
        <h3>Address</h3>
        <span>Group Floor</span>
        <span>Building 23, South Wig</span>
        <span>CSIR, Meiring Naude Road</span>
        <span>Brummeria, Pretoria</span>


      </div>

      <div>
        <h3>Contacts</h3>
        <span>enquiries@ticeafrica.com </span>
        <span>012-111-7864</span>
      </div>
      <div id="tut">
        <img src="https://tuteh-storage.s3-eu-west-1.amazonaws.com/assets/tut logo.png" alt="">
      </div>
    </div>
  </div>
  <div class="hr wrapper"></div>
  <div class="wrapper">
    <h4>© 2022 Tshwane Institute for Continuing Education. All Rights Reserved</h4>

    <a
      href="https://tice-storage.s3.eu-west-1.amazonaws.com/POPIA+Policy%5B14274%5D.pdf"
      target="_blank">Privacy Policy</a>

  </div>

</footer>
