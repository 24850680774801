<header #hero>

<!-- Global site tag (gtag.js) - Google Analytics -->
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-8T3VFGQZZH');
</script>


  <div class="max-wrapper">
    <div id="logo"></div>
    <button id="menu" routerLink="/">
      <span class="material-icons">
        arrow_back
      </span>
    </button>
    <nav>
      <ul>
        <ul>
          <li routerLink="/"><a>HOME</a></li>
        </ul>
      </ul>

    </nav>
  </div>
</header>

<main>
  <div class="wrapper">
    <div id="breadcrumb">
      <span routerLink="/">home</span>
      <span>/</span>
      <span>courses</span>
    </div>


    <div id="center">
      <div id="custom-toggle">
        <button (click)="isUpcoming = true; activeCategory = categories[0]" [ngClass]="{'active': isUpcoming}">Upcoming courses</button>
        <button (click)="isUpcoming = false; activeCategory = categories[0]" [ngClass]="{'active': !isUpcoming}">View all courses</button>
      </div>
    </div>

    <div id="loader" *ngIf="loading">
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>


    <div id="library">
      <div id="left">
        <div class="content">
          <div class="heading">
            Categories
          </div>
          <div class="sub-heading">
            Filter by category
          </div>

        </div>

        <ng-container *ngIf="isUpcoming; else elseTemplate">
          <div class="filter" *ngFor="let item of upcomingCoursesCategories" (click)="filterUpcoming(item)">
            <span [ngClass]="{'active_filter': item?.CategoryID === activeCategory?.CategoryID}">{{item?.Name}}</span>
          </div>
        </ng-container>
        <ng-template #elseTemplate>
          <div class="filter" *ngFor="let item of categories" (click)="filter(item)">
            <span [ngClass]="{'active_filter': item?.CategoryID === activeCategory?.CategoryID}">{{item?.Name}}</span>
          </div>
        </ng-template>
        

        

      </div>

      <div id="right">
        <div id="status">
          <div class="content">
            <div class="heading">
              Filtered Courses ({{isUpcoming ? filtredUpcomingCourses?.length : filtredCourses?.length}})
            </div>
            <div class="sub-heading">
              {{activeCategory?.Name == 'All' ? 'All Courses' : 'Courses in ' + activeCategory?.Name }}
            </div>

          </div>

        </div>

        <ng-container *ngIf="isUpcoming; else allCourses">
          <div id="courses">

            <div id="not-found" *ngIf="filtredUpcomingCourses?.length == 0">
              <div id="img"></div>
              <small>Courses coming soon, stay tuned</small>
            </div>


            <div class="course" *ngFor="let item of filtredUpcomingCourses; let i = index" 
              [ngClass]="{'expanded': expandedUpcoming == i}">
              <img [src]="item?.Media ? item?.Media['Template.HeroImage'].Uri : 'https://via.placeholder.com/400x400.png/FE3FFE/fff?text=Image+coming+soon'" alt="">
              <div class="content-box">
                <div class="course-title" (click)="expandUpcoming(item,i)">{{item?.Name}}</div>
                <div class="course-description" (click)="expandUpcoming(item,i)">
                  {{item?.Summary}}
                </div>
              
                <div [innerHTML]="item?.Description.Text" class="extras">
          
                </div>

                <div class="actions">

                  <a href="{{item?.ViewUri}}" target="_blank">
                    <button>
                      <span class="material-icons">
                        east
                      </span>
                      Learn More
                    </button>
                  </a>

                  <div class="price">
                    <span class="material-icons">
                      local_offer
                    </span>
                    {{item?.AdvertisedOffers[0].OfferAmount.FormattedAmountTaxInclusive}}
                  </div>

                  <div class="date" *ngIf="item?.AdvertisedDuration != ''">
                    <span class="material-icons">
                      date_range
                    </span>
                    {{item.StartDateTime | date}}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-template #allCourses>
          <div id="courses">

            <div id="not-found" *ngIf="filtredCourses?.length == 0">
              <div id="img"></div>
              <small>Courses coming soon, stay tuned</small>
            </div>


            <div class="course" *ngFor="let item of filtredCourses; let i = index" 
              [ngClass]="{'expanded': expanded == i}">

              <img [src]="item?.Media['Template.HeroImage'].Uri" alt="">
              <div class="content-box">
                <div class="course-title" (click)="expand(item,i)">{{item?.Name}}</div>
                <div class="course-description" (click)="expand(item,i)">
                  {{item?.Description.Summary}}
                </div>
                <div [innerHTML]="html" class="extras">

                </div>

                <div class="actions">

                  <a href="{{item?.RegisterInterestUri}}" target="_blank">
                    <button>
                      <span class="material-icons">
                        add_shopping_cart
                      </span>
                      Register Interest
                    </button>
                  </a>

                  <div class="price">
                    <span class="material-icons">
                      local_offer
                    </span>
                    {{item?.BestAdvertisedOffers[0].OfferAmount.FormattedAmountTaxInclusive}}
                  </div>

                  <div class="date" *ngIf="item?.AdvertisedDuration != ''">
                    <span class="material-icons">
                      date_range
                    </span>
                    {{item?.AdvertisedDuration}}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </ng-template>

      </div>



    </div>


  </div>

</main>

<footer>
  <div class="wrapper">
    <div class="col-4">
      <div>
        <h3>Links</h3>
        <span>Home</span>
        <span>Courses</span>
        <span>News</span>
        <span>Contact Us</span>


      </div>
      <div>
        <h3>Address</h3>
        <span>Group Floor</span>
        <span>Building 23, South Wing</span>
        <span>CSIR, Meiring Naude Road</span>
        <span>Brummeria, Pretoria</span>


      </div>

      <div>
        <h3>Contacts</h3>
        <span>enquiries@ticeafrica.com </span>
        <span>012-111-7864</span>
      </div>

      <div id="tut">
        <img src="../../../assets/tut logo.png" alt="">
      </div>
    </div>
  </div>
  <div class="hr wrapper"></div>
  <div class="wrapper">
    <h4>© 2022 Tshwane Institute for Continuing Education. All Rights Reserved</h4>
  </div>
  
  <div class="wrapper">
    <a 
      href="https://tice-storage.s3.eu-west-1.amazonaws.com/POPIA+Policy%5B14274%5D.pdf">Privacy Policy</a>
  
  </div>

</footer>
