import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ArloService } from 'src/app/services/arlo/arlo.service';
import { BlogService } from 'src/app/services/blog/blog.service';
declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  categories;
  testimonialArr = [
    {
      names: "Duduzile Sibisi",
      role: "Applied Statistical Methods for Data Analysis SLP Candidate",
      testimonials: "Attending the course helped me a lot, not only was I helped on how to go about my research but networking with others."
    },
    {
      names: "Mkateko Vivian Madunda",
      role: "Applied Statistical Methods for Data Analysis SLP Candidate",
      testimonials: "What I liked the most is the passion Pro Zeleke Worku has for lecturing, I am not really a statistical person, however, Prof Worku made me love statistical analysis."
    },
    {
      names: "Lulama Scott",
      role: "Applied Statistical Methods for Data Analysis SLP Candidate",
      testimonials: "I thoroughly enjoyed the course. I believe it will help me a lot with my research, the classes are small which allows us to get more attention to the methods we don’t understand."
    },
    {
      names: "Mashao Lekganyane",
      role: "Appliance Repairs SLP Candidate",
      testimonials: "The training was good, although it was short but I am looking for more practical training so that I can gain more confidence before opening a business."
    },
    {
      names: "Themba Ngobe",
      role: "Appliance Repairs SLP Candidate",
      testimonials: "I have such great confidence that I can fix anything to do with appliances. Should there be another opportunity in the future with any skills that I can benefit from, don’t hesitate by letting me know."
    },
  ];


  faqs = [
    {
      question: 'Who is Tshwane Institute for Continuing Education (TICE)?',
      answer: 'TICE is a wholly owned subsidiary of the TUT Enterprise Holdings (TUTEH), which itself is a wholly owned company of Tshwane University of Technology (TUT).'
    },
    {
      question: 'Does TICE offer formal qualifications?',
      answer: 'TICE offers TUT certified, non-credit bearing short learning programmes.'
    },
    {
      question: 'How are the courses delivered?',
      answer: 'The mode of delivery is contact and virtual.'
    },
    {
      question: 'Does TICE offer Continuing Professional Development programmes?',
      answer: 'Yes, we offer professional body accredited programmes'
    },
  ]
  activeTestimonials = this.testimonialArr[0];
  activeFaq;

  testimonial = 0;
  activeSlide = 1;
  topOffset = "0px";

  isMenuOpened = false;

  constructor(
    private arloService: ArloService,
    private router: Router,
    private blogSrvc: BlogService
  ) {


    this.arloService.getCourses().then(
      resp => {
        this.arloService.setCourses(resp['Items'])
      },
      error => {
        // console.log(error);
      }
    ).catch(error => console.log(error));

    this.arloService.getCategories().then(resp => {
    //  console.log(resp);
      this.categories = resp['Items'];
      this.arloService.setCategories(this.categories);
    },
      error => {
        // console.log(error);
      }
    ).catch(error => console.log(error));

  }

  inteval;

  ngOnInit(): void {
    window.scrollTo({
      top: 0,
    });

    this.inteval = setInterval(() => {
      if (this.activeSlide == 3) {
        this.activeSlide = 1;
        this.topOffset = 0 + 'px';
      } else {
        this.activeSlide++;
        this.topOffset = "-" + (this.activeSlide - 1) * 600 + "px"
      }

    }, 12000)
  }

  openMenu() {
    this.isMenuOpened = this.isMenuOpened ? false : true;
  }

  next() {
    console.log("wwww");
    clearInterval(this.inteval);

    if (this.activeSlide <= 2) {
      this.activeSlide++;
      this.topOffset = "-" + (this.activeSlide - 1) * 600 + "px"
    } else {
      this.activeSlide = 1;
      this.topOffset = "-" + (this.activeSlide - 1) * 600 + "px"
    }



    this.inteval = setInterval(() => {
      if (this.activeSlide == 3) {
        this.activeSlide = 1;
        this.topOffset = 0 + 'px';
      } else {
        this.activeSlide++;
        this.topOffset = "-" + (this.activeSlide - 1) * 600 + "px"
      }

    }, 12000)

  }

  prev() {
    console.log("wwww");
    clearInterval(this.inteval);

    if (this.activeSlide > 1) {
      this.activeSlide--;
      this.topOffset = "-" + (this.activeSlide - 1) * 600 + "px"
    } else {
      this.activeSlide = 3;
      this.topOffset = "-" + (this.activeSlide - 1) * 600 + "px"
    }



    this.inteval = setInterval(() => {
      if (this.activeSlide == 3) {
        this.activeSlide = 1;
        this.topOffset = 0 + 'px';
      } else {
        this.activeSlide++;
        this.topOffset = "-" + (this.activeSlide - 1) * 600 + "px"
      }

    }, 12000)

  }

  scroll(el: HTMLElement) {

    window.scrollTo({
      top: el.offsetTop - 95,
      behavior: "smooth"
    });
  }

  sortByAlphabet(a, b) {
    if (a.Name < b.Name) {
      return -1;
    }
    if (a.Name > b.Name) {
      return 1;
    }
    return 0;
  }

  testimonialScroll() {
    this.testimonial++;
    if (this.testimonial == this.testimonialArr.length) {
      this.testimonial = 0;
    }

    this.activeTestimonials = this.testimonialArr[this.testimonial]
  }

  viewCourse(course) {
    console.log(course);
    this.router.navigate(['courses/' + course.Name], { state: { data: course } });
  }




}
