<header #hero>
    
    <!-- Global site tag (gtag.js) - Google Analytics -->

<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-8T3VFGQZZH');
</script>


  <div class="max-wrapper">
    <div id="logo"></div>
    <nav>
      <ul>
        <ul>
          <li routerLink="/"><a>HOME</a></li>
        </ul>
      </ul>

    </nav>
  </div>
</header>
<main>
  <div class="wrapper">
    <div id="breadcrumb">
      <span routerLink="/">home</span>
      <span>/</span>
      <span>News</span>
    </div>
    <div id="loader" *ngIf="loading">
      <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div id="articles">
      <div class="article" *ngIf="activeArticle">
        <div class="content">
          <div class="heading">
            {{activeArticle?.title}}
          </div>
          <div class="sub-heading">
            {{activeArticle?.description}}
          </div>
          <small>{{activeArticle.createdAt | date}} | {{activeArticle.readDuration}} read</small>
        </div>
        <div class="body" [innerHTML]="activeArticle.body">
          <p>text</p>
          <p></p>
          <p>text</p>
        </div>
      </div>
    </div>
    <h3 *ngIf="blogs.length > 0">You may also like this</h3>
    <div id="publications"  *ngIf="links.length > 0">

      <div class="publication" *ngFor="let link of links">
      
        <div class="title">{{link?.title | titlecase}}</div>
        <div> <small>{{ link?.createdAt | date }}</small></div>
       <p>{{link.description}}</p>
       <button (click)="viewArticle(link)"> <span class="material-icons">
        east
      </span> Read More</button>
      </div>
    </div>


    <h3 *ngIf="blogs.length == 0 && !loading " style="text-align: center; padding: 40px 0 120px;">No Articles have been posted.</h3>

  </div>

</main>

<footer>
  <div class="wrapper">
    <div class="col-4">
      <div>
        <h3>Links</h3>
        <span>Home</span>
        <span>Courses</span>
        <span>News</span>
        <span>Contact Us</span>


      </div>
      <div>
        <h3>Address</h3>
        <span>Group Floor</span>
        <span>Building 23, South Wing</span>
        <span>CSIR, Meiring Naude Road</span>
        <span>Brummeria, Pretoria</span>


      </div>

      <div>
        <h3>Contacts</h3>
        <span>enquiries@ticeafrica.com </span>
        <span>012-111-7864</span>
      </div>

      <div id="tut">
        <img src="../../../assets/tut logo.png" alt="">
      </div>
    </div>
  </div>
  <div class="hr wrapper"></div>
  <div class="wrapper">
    <h4>© 2022 Tshwane Institute for Continuing Education. All Rights Reserved</h4>
  </div>
  
  <div class="wrapper">
    <a 
      href="https://tice-storage.s3.eu-west-1.amazonaws.com/POPIA+Policy%5B14274%5D.pdf">Privacy Policy</a>
  
  </div>

</footer>

