import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  private mailApi = 'https://y9rv1r42ei.execute-api.eu-west-1.amazonaws.com/dev/contactUsEmail';

  constructor(private http: HttpClient) { }

  sendEmail(enquiry){
    return this.http.post<any>(this.mailApi, enquiry).toPromise();
  }
}
