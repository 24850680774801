import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ArloService } from 'src/app/services/arlo/arlo.service';
declare var $;
@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CoursesComponent implements OnInit {
  activeCategory
  categories = [
    {
      CategoryID: 0,
      Name: "All"
    }
  ];

  courses;
  upcomingCourses;
  expanded;
  expandedUpcoming;
  isUpcoming = true;
  loading = true;

  upcomingCoursesCategories = [
    {
      CategoryID: 0,
      Name: "All"
    }
  ];

  filtredCourses;
  constructor(
    private arloService: ArloService,
    private router: Router
  ) {

    this.arloService.getCategories().then(
      categories => {

        this.categories = this.categories.concat(categories['Items']).sort(this.sortByAlphabet);
        //console.log(this.categories);
        this.activeCategory = this.categories[0];

        this.arloService.getCourses().then(
          courses => {
            console.log(courses['Items']);

            this.courses = courses['Items'];

            this.filter(this.activeCategory);
          }
        );

      }
    );

    this.arloService.getUpcomingCourses().then(
      upcomingCourses => {
        //console.log(upcomingCourses['Items']);
        this.upcomingCourses = upcomingCourses['Items'];
        this.filtredUpcomingCourses = this.upcomingCourses;

        this.upcomingCourses.forEach(element => {
          if (!this.alreadyExist(element.Categories[0])) {
            this.upcomingCoursesCategories.push(element.Categories[0]);
          }
        });
        this.upcomingCoursesCategories.sort(this.sortByAlphabet);

        this.loading = false;
        //console.log(this.upcomingCoursesCategories);
      }
    );





  }

  alreadyExist(obj) {
    let found = false;
    this.upcomingCoursesCategories.forEach(element => {
      if (obj.Name == element.Name) {
        found = true;
      }
    });

    return found;
  }

  sortByAlphabet(a, b) {
    if (a.Name < b.Name) {
      return -1;
    }
    if (a.Name > b.Name) {
      return 1;
    }
    return 0;
  }

  html;

  expandUpcoming(course, i) {
   // console.log(course);

    if (i == this.expandedUpcoming) {
      this.expandedUpcoming = null;
      return
    }

    this.expandedUpcoming = i;

  }
  expand(course, i) {

    if (i == this.expanded) {
      this.expanded = null;
      return
    }
    //console.log(course);

    let html = "";

    course.Description.ContentFields.forEach(element => {
      html += `<h3>${element.FieldName}</h3>${element.Content.Text}`
    });

    this.expanded = i;
    //console.log(html);
    this.html = html
    //this.extrasRef.nativeElement.innerHTML = html;
  }

  filter(category) {
    //console.log(category);
    this.expanded = null;
    if (category.CategoryID == 0) {
      this.activeCategory = category
      this.filtredCourses = this.courses;
      // console.log("stopped");

      return;
    }

    this.activeCategory = category
    this.filtredCourses = this.courses.filter(
      course => {
        if (typeof course.Categories === "undefined") {
          //console.log("oops");
          return false
        }

        if (this.activeCategory.CategoryID === course.Categories[0].CategoryID) {
          return true
        }
      }
    )
  }

  filtredUpcomingCourses

  filterUpcoming(category) {
    console.log(category);
    this.expanded = null;
    if (category.CategoryID == 0) {
      this.activeCategory = category
      this.filtredUpcomingCourses = this.upcomingCourses;
      // console.log("stopped");
      return;
    }

    this.activeCategory = category
    this.filtredUpcomingCourses = this.upcomingCourses.filter(
      course => {
        if (typeof course.Categories === "undefined") {
          //console.log("oops");
          return false
        }

        if (this.activeCategory.CategoryID === course.Categories[0].CategoryID) {
          return true
        }
      }
    );

  }

  ngOnInit(): void {
    window.scrollTo({
      top: 0,
    });
  }



}
