import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactService } from '../../services/contact/contact.service';
import { Validation } from './../_helpers/validation';
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
  {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
  {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
  {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
  {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
  {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
  {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
  {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
  {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
  {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
];

@Component({
  selector: 'app-maintenance-planner',
  templateUrl: './maintenance-planner.component.html',
  styleUrls: ['./maintenance-planner.component.scss']
})
export class MaintenancePlannerComponent implements OnInit {

  displayedColumns: string[] = ['position', 'name'];
  dataSource = ELEMENT_DATA;
  contactForm: FormGroup;
  submitted = false;
  loading = false;
  querySent = false;

  constructor(
    private _formBuilder: FormBuilder,
    private contactSrvc: ContactService,
  ) { }

  ngOnInit(): void {

    this.contactForm = this._formBuilder.group({
      name: [null,
        [
         Validators.required,
         Validators.maxLength(255),
         Validators.minLength(2)
        ]
      ],
      message: [null,
        [
         Validators.required,
         Validators.maxLength(255),
         Validators.minLength(2)
        ]
      ],
      replyTo: [null, [Validators.required, Validators.email]],
      subject: [null,
        [
         Validators.required,
         Validators.maxLength(255),
         Validators.minLength(2)
        ]
      ]
    })
  }

  get f(): { [key: string]: AbstractControl } {
    return this.contactForm.controls;
  }

  feedbackMessage;

  contactUs(){
    this.submitted = true;
    this.loading = true;
      let email = {
        name: this.contactForm.value.name,
        message: this.contactForm.value.message,
        replyTo: this.contactForm.value.replyTo,
        subject: this.contactForm.value.subject,
      }

      ////console.log(email);

      this.contactSrvc.sendEmail(email).then(
        resp => {
          ////console.log(resp);
          this.loading = false;
          this.querySent = true;
          this.feedbackMessage = 'Your query has been successfully sent.';
          this.contactForm.reset();
        }
      ).catch(
        error => {
          //// console.log(error);
          this.loading = false;
          this.feedbackMessage = 'Unable to submit your query, please try again later.';
        }
      )
  }
}
