// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrlBlog: "https://dev.api.blog.t-res.co.za/",
  arloBaseUrl: 'https://tutenterprise.arlo.co/api/2012-02-01/pub/resources',
  apiKey: 'a8f64661-f4af-4df7-a6ea-26e72b640624',
  organisationId: 'f30550bf-49fb-4444-bd1d-715c3c7276df',
};