<header #hero>

  <div class="max-wrapper">
    <div id="logo"></div>
    <button id="menu" routerLink="/">
      <span class="material-icons">
        arrow_back
      </span>
    </button>
    <nav>
      <ul>
        <ul>
          <li routerLink="/"><a>Home</a></li>
          <!-- <li routerLink="/"><a>Fevertree Funding</a></li> -->
        </ul>
      </ul>

    </nav>
  </div>
  </header>

  <section>
    <div id="banner">

      <div class="wrapper">
        <div id="vertical-slides-musk">
          <div id="vertical-slides" >
            <div class="slide">
              <div class="row">
                <div class="col-1">
                  <div class="heading">
                    Maintenance <span>Planner</span> Short Learning Programme
                  </div>
                  <!-- <div class="call-to-actions">
                    <button routerLink="/about">Learn more <span class="material-icons">
                        east
                      </span></button>
                  </div> -->
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
</section>

<main>
  <div class="wrapper">
    <div id="values">
      <div id="">
        <!-- <div class="card">
          <img class="logos" src="https://tuteh-storage.s3-eu-west-1.amazonaws.com/assets/tut_logo.png">
        </div> -->
        <p class="logo">
          <img class="" width="300" src="https://tuteh-storage.s3-eu-west-1.amazonaws.com/assets/fevertree.png">
        </p>

      </div>

    </div>
    <div class="">
      <div class="content">
        <div class="heading">
          Maintenance Planner Short Learning Programme
        </div>
        <div class="sub-heading" style="padding-top: 30px;">

        </div>
        <h2>Programme Description</h2>
       </div>
        <p>
          This programme was developed to equip Tradespeople, Supervisors, Engineers, and Technicians
           aspiring to pursue a career in Maintenance Planning and
           those who are currently working in the field but do not possess formal certification.
          <br><br>
          It is made up of technical and soft skills to ensure balance and
          alignment in successful execution of tasks. For those willing to
          enhance their skills in specific areas, individual units are offered with prerequisites.
        </p>
        <section>
          <br>
          <div>
            <button mat-raised-button id="brochure-btn">
              <a id="download" href="https://tuteh-storage.s3.eu-west-1.amazonaws.com/attachments/Maintenance_Planner_September_2022.pdf" download="maintenace-planner-brochure" target="_blank">Download Brochure </a>
              <mat-icon style="color: #ffffff">cloud_download</mat-icon>
            </button>
          </div>
        </section>
    </div>
      <div class="two-col wrapper">
        <div class="content">
          <div class="container flex">
            <div class="flex_content flex2">
                <h2>Technical Skills</h2>
                <ul>
                    <li><span>&#9737;</span>Foundation unit Introduction to Intergrated Asset Management</li>
                    <li><span>&#9737;</span>Key maintenance workflow elements</li>
                    <li><span>&#9737;</span>Planning a simple maintenance task</li>
                    <li><span>&#9737;</span>Planning techniques for projects and shutdowns</li>
                    <li><span>&#9737;</span>The importance of configuration and documentation control</li>
                    <li><span>&#9737;</span>Computerised Maintenance Management Software</li>
                </ul>

            </div>
            <div class="flex_content">
                <h2>Soft Skills</h2>
                <ul>
                    <li><span>&#9737;</span>Effective communication</li>
                    <li><span>&#9737;</span>Facilitation</li>
                    <li><span>&#9737;</span>Effective time management</li>
                    <li><span>&#9737;</span>Customer service</li>
                    <li><span>&#9737;</span>Leadership</li>
                    <li><span>&#9737;</span>Problem solving and decision making</li>
                    <li><span>&#9737;</span>Negotiation</li>
                </ul>
            </div>

            <div class="flex_content flex3">
              <h2>About the Programme</h2>
              <ul>
                  <li><span>&#9737;</span><b>49 CPD points awarded by SAAMA
                  </b><br>
                    Certified by Tshwane University of Technology
                  </li>
                  <li>
                    <ul class="inner-list">
                        <li><b>Duration:</b> 12 Months</li>
                        <li><b>Attend:</b> 3 hours a week</li>
                        <li><b>Cost:</b> R42 000</li>
                        <div><br>
                            Finance Option Available<br>
                          <button mat-raised-button id="apply">
                            <a href="https://www.ftapp.co.za/app/TI?source=TIOnline">APPLY HERE</a>
                          </button>
                        </div>
                    </ul>
                  </li>
                  <li><span>&#9737;</span><b>Entry Requirements</b>
                    <ul class="inner-list">
                      <li>NQF Level 4 with mathematics and physical science</li>
                      <li>NQF Level 4 with 3 years’ maintenance planning experience</li>
                      <li>Or a qualified Artisian</li>
                    </ul>
                  </li>
                  <!-- <li><span>&#9737;</span>Customer service</li> -->
              </ul>
          </div>
        </div>
        </div>
      </div>

    <div class="wrapper">
      <div class="content">
        <div class="heading">
          Programme Outcomes
        </div>
        <p>
          By successfully completing this training programme you will gain skills and
          knowledge necessary for effective Maintenance Planning and learn how to:
        </p>
        <ul>
          <li><span>&#9737;</span> Perform the functions of maintenance planning in full support of the integrated
            asset and maintenance management system.
          </li>
          <li><span>&#9737;</span> Acquire a foundational understanding of Maintenance Work Management in its
            organizational context.
          </li>
          <li><span>&#9737;</span> Facilitate the management (planning, scheduling, and coordination) of
            maintenance work and the execution thereof in a stepwise manner.
          </li>
          <li><span>&#9737;</span> Maintain and update master data to uphold maintenance work quality and
            facilitate easy access to maintenance information.
          </li>
          <li><span>&#9737;</span> Determine the scope of work for a maintenance task to allow quality work
            execution, maximally utilizing required resources.
          </li>
          <li><span>&#9737;</span> Identify, prioritize, plan, schedule, execute and close-out maintenance tasks
            and associated master data to allow quality work execution, maximally utilizing required resources.
            </li>
          <li><span>&#9737;</span> Learn how to apply the critical path method in planning and scheduling of
            maintenance projects to be executed within time and budget constraints.
          </li>
          <li><span>&#9737;</span> Compile and sequence activities in an activity description list, discuss the
            importance of documentation control in asset maintenance and management and
            the different indexing systems used asset maintenance and management.
          </li>
          <li><span>&#9737;</span> Identify the elements of good Documentation Control in asset maintenance and
            management.
          </li>
          <li><span>&#9737;</span> Identify and discuss the consequences of poor Documentation Control that are
            used in asset maintenance and management.

          </li>
          <li><span>&#9737;</span> Identify and discuss maintenance task management software applications that
            are used in physical asset management.
          </li>
          <li><span>&#9737;</span> Introduce and apply software applications used for maintenance projects and shutdown management.
          </li>
          <li><span>&#9737;</span> Identify and discuss general software applications used in management of maintenance information.
          </li>
        </ul>
      </div>
    </div>
    <br>
    <div class="content">
    <div class="background">
      <div class="container">
        <div class="screen">
          <div *ngIf="!loading" class="screen-body">
            <div class="screen-body-item left">
              <div class="app-title">
                <span>Contact Us</span>
              </div>
              <!-- <div class="app-contact">CONTACT INFO : +62 81 314 928 595</div> -->
            </div>
            <div class="screen-body-item">
              <div *ngIf="querySent" class="screen-body-item">
                <p style="color:green"> {{feedbackMessage}} </p>
              </div>
              <form [formGroup]="contactForm" (ngSubmit)="contactUs()">
                <div class="app-form">
                  <div class="app-form-group">
                    <input class="app-form-control" formControlName="name" placeholder="First Name"
                    type="text"
                    [ngClass]="{ 'is-invalid': submitted && f.name.errors }">

                    <small *ngIf="submitted && f.name.errors" class="invalid-feedback">
                      <div *ngIf="f.name.errors.required">Name is required</div>
                    </small>

                  </div>
                  <div class="app-form-group">
                    <input class="app-form-control" formControlName="replyTo"  placeholder="Email" type="email" required
                    [ngClass]="{ 'is-invalid': submitted && f.replyTo.errors }">

                    <small *ngIf="submitted && f.replyTo.errors" class="invalid-feedback">
                      <div *ngIf="f.replyTo.errors.required">Email is required</div>
                      <div *ngIf="f.replyTo.errors.email">Email is invalid</div>
                    </small>

                  </div>

                  <div class="app-form-group">
                    <label for="reason" id="reason-label">Reason for contact</label>
                    <select class="app-form-control" formControlName="subject" id="reason"
                    [ngClass]="{ 'is-invalid': submitted && f.subject.errors }"
                    >
                      <option class="app-form-control" value="COURSE-ENQUIRIES">Course Enquiries</option>
                      <option class="app-form-control" value="FEEDBACK">Feedback</option>
                      <option class="app-form-control" value="OTHER-GENERAL">Other/General</option>
                    </select>

                    <small *ngIf="submitted && f.subject.errors" class="invalid-feedback">
                      <div *ngIf="f.subject.errors.required">Reason is required</div>
                    </small>
                  </div>
                  <div class="app-form-group message">
                    <input class="app-form-control" formControlName="message" placeholder="Message"
                    [ngClass]="{ 'is-invalid': submitted && f.message.errors }">
                    <small *ngIf="submitted && f.message.errors" class="invalid-feedback">
                      <div *ngIf="f.message.errors.required">Message is required</div>
                    </small>
                  </div>
                  <div class="app-form-group buttons">
                    <!-- <button class="app-form-button">CANCEL</button> -->
                    <button class="app-form-button" type="submit">SUBMIT</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <br>
          <div *ngIf="loading">
            <div class="loader"></div>
          </div><br>

        </div>
      </div>
    </div>
    </div>
  </div>
</main>

<footer>
  <div class="wrapper">
    <div class="col-4">
      <div>
        <h3>Links</h3>
        <a routerLink="/"><span>Home</span></a>
        <a routerLink="/maintenace-planner"><span>Maintenance Planner</span></a>
      </div>
      <div>
        <h3>Address</h3>
        <span>Group Floor</span>
        <span>Building 23, South Wig</span>
        <span>CSIR, Meiring Naude Road</span>
        <span>Brummeria, Pretoria</span>
      </div>

      <div>
        <h3>Contacts</h3>
        <span>enquiries@ticeafrica.com </span>
        <span>012-111-7864</span>
      </div>

      <div id="tut">
        <img src="https://tuteh-storage.s3-eu-west-1.amazonaws.com/assets/tut logo.png" alt="">
      </div>
    </div>
  </div>
  <div class="hr wrapper"></div>
  <div class="wrapper">
    <h4>© 2022 Tshwane Institute for Continuing Education. All Rights Reserved</h4>

    <a
      href="https://tice-storage.s3.eu-west-1.amazonaws.com/POPIA+Policy%5B14274%5D.pdf"
      target="_blank">Privacy Policy</a>

  </div>

</footer>
