<header #hero>


  <div class="max-wrapper">
    <div id="logo"></div>
    <button id="menu" (click)="openMenu()">
      <span class="material-icons">
        {{isMenuOpened ? "close" : "menu"}}
      </span>
    </button>

    <div id="mobile-menu" [ngClass]="{'opened-menu': isMenuOpened}">
      <div (click)="scroll(hero)"><a>Home</a></div>

      <div routerLink="/about"><a>About Us</a></div>

      <div routerLink="/courses"><a>Courses</a></div>

      <div (click)="scroll(testimonials)"><a>Testimonials</a></div>

      <div (click)="scroll(gallery)"><a>Gallery</a></div>

      <div routerLink="/news"><a>News</a></div>

      <div (click)="scroll(partners)"><a>Partners</a></div>

      <div id = "socials">
        <div (click)="scroll(partners)"><a href="https://www.facebook.com/TICEAfrica" target="_blank" class="icon facebook"></a></div>

        <div (click)="scroll(partners)"><a
            href="https://www.linkedin.com/school/tshwane-institute-for-continuing-education" target="_blank" class="icon linkedin"></a></div>
      </div>


    </div>
    <nav>
      <ul>
        <li (click)="scroll(hero)"><a>Home</a></li>
        <li routerLink="/about"><a>About Us</a></li>
        <li routerLink="/courses"><a>Courses</a></li>
        <li (click)="scroll(testimonials)"><a>Testimonials</a></li>
        <li (click)="scroll(gallery)"><a>Gallery</a></li>
        <li routerLink="/news"><a>News</a></li>
        <li (click)="scroll(partners)"><a>Partners</a></li>
        <li (click)="scroll(partners)"><a href="https://www.facebook.com/TICEAfrica" target="_blank"
            class="icon facebook"></a></li>
        <li (click)="scroll(partners)"><a
            href="https://www.linkedin.com/school/tshwane-institute-for-continuing-education" target="_blank"
            class="icon linkedin"></a></li>
      </ul>
    </nav>
  </div>

</header>
<section>
  <div id="banner">
    <button class="navigation-left" (click)="prev()"><span class="material-icons">
        chevron_left
      </span></button>
    <div class="wrapper">
      <div id="vertical-slides-musk">
        <div id="vertical-slides" [style.marginTop]="topOffset">
          <div class="slide">
            <div class="row">
              <div class="col-1">
                <div class="heading">
                  Embrace <span>your</span> learning journey
                </div>
                <div class="statement">
                  Focused on applied knowledge for better return on investment for Participants
                </div>
                <div class="call-to-actions">
                  <button routerLink="/about">Learn more <span class="material-icons">
                      east
                    </span></button>
                  <!-- <button >Talk to us</button> -->
                </div>
              </div>
            </div>
          </div>
          <div class="slide">
            <div class="row">
              <div class="col-1">
                <div class="heading">
                  Our <span>Vision</span>
                </div>
                <div class="statement">
                  To be the most effective and preferred facilitator of skills, continuing professional development and
                  knowledge enhancer in the African continent
                </div>
                <div class="call-to-actions">
                  <button routerLink="/about">Learn more <span class="material-icons">
                      east
                    </span></button>
                  <!-- <button >Talk to us</button> -->
                </div>
              </div>
            </div>
          </div>
          <div class="slide">
            <div class="row">
              <div class="col-1">
                <div class="heading">
                  Our <span>Mission</span>
                </div>
                <div class="statement">
                  To enable valuable lifelong learning and professional development for both private and public sectors,
                  including communities through research-informed content.
                </div>
                <div class="call-to-actions">
                  <button routerLink="/about">Learn more <span class="material-icons">
                      east
                    </span></button>
                  <!-- <button >Talk to us</button> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="pagination">
          <span [ngClass]="{'active': activeSlide == 1}"></span>
          <span [ngClass]="{'active': activeSlide == 2}"></span>
          <span [ngClass]="{'active': activeSlide == 3}"></span>

        </div>
      </div>
    </div>
    <button class="navigation-right" (click)="next()"><span class="material-icons">
        chevron_right
      </span></button>
  </div>
</section>
<main>
  <div id="about-intro" #about>
    <div class="wrapper">
      <div id="services">
        <div class="service">
          <img src="https://tuteh-storage.s3-eu-west-1.amazonaws.com/assets/3d-hands-classy-217.png" alt="">
          <div class="heading">Short Learning Programmes</div>
          <div class="mini-hr"></div>
          <div class="summary">
            <ul>
              <li>End-user centered content</li>
              <li>Qualified facilitators</li>
              <li>Professional body accredited Programmes</li>
              <li>Professional client interface</li>
            </ul>
          </div>
        </div>

        <div class="service">
          <img src="https://tuteh-storage.s3-eu-west-1.amazonaws.com/assets/3d-hands-classy-163.png" alt="">
          <div class="heading">Needs Identification & Analysis</div>
          <div class="mini-hr"></div>
          <div class="summary">
            <ul>
              <li>Identify the gaps</li>
              <li>Develop a plan of action</li>
              <li>Allocate resources</li>
              <li>Organisational improvements</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="operations">

    <div id="fake-about" class="two-col wrapper">

      <div class="content">
        <div class="heading">
          TICE
        </div>
        <div class="sub-heading">Tshwane Institute for Continuing Education</div>
        <p>
          Tshwane Institute for Continuing Education (TICE) is a wholly owned subsidiary of TUT Enterprise Holdings
          (TUTEH). The parent company, TUTEH, itself is a company of the Tshwane University of Technology (TUT) with a
          mandate to spearhead all commercial activities on behalf of and for the benefit of TUT. Given its pedigree as
          an offshoot of a University of Technology (UoT), TICE is well poised to be the catalyst of human capital
          transitions from:
        </p>
        <ul>
          <li><span>&#9737;</span> Unskilled to skilled</li>
          <li><span>&#9737;</span> Technical to managerial / entrepreneurial</li>
          <li><span>&#9737;</span> Small business owner to big business operator</li>
          <li><span>&#9737;</span> Local to global</li>
          <li><span>&#9737;</span> Present to future world of work</li>
        </ul>
        <p>Each of these transitions necessitates learning and acquisition of a new set of competencies and skills, the
          role TICE aims to fulfil.</p>

      </div>

      <div id="colloboraters">
        <a href="https://www.tut.ac.za/" target="_blank"><img
            src="https://tuteh-storage.s3-eu-west-1.amazonaws.com/assets/tut logos.png" alt=""></a>
        <a href="https://www.tutenterprise.co.za/" target="_blank"><img
            src="https://tuteh-storage.s3-eu-west-1.amazonaws.com/assets/tuteh-logo.png" alt=""></a>

      </div>


    </div>
    <br><br><br>
    <div class="two-col wrapper">

      <div class="img">
        <img src="https://tuteh-storage.s3-eu-west-1.amazonaws.com/assets/banner-girl.png" alt="">
      </div>

      <div class="content">
        <div class="heading">
          Why enrol with TICE
        </div>
        <div class="sub-heading">
          Quality Short Learning Programmes developed in collaboration with academics and subject matter experts.
        </div>
        <ul>
          <li><span>&#9737;</span> Certified by an Institution of Higher Education.</li>
          <li><span>&#9737;</span> We offer Professional Body Accredited Programmes.</li>
          <li><span>&#9737;</span> We collaborate with both private and public sectors.</li>
          <li><span>&#9737;</span> We develop researched informed Short Learning Programmes.</li>
        </ul>
      </div>

    </div>

  </div>

  <div class="hr wrapper"></div>


  <div id="testimonials" #testimonials>
    <div class="two-col wrapper">
      <div class="content">
        <div class="heading">
          Testimonials
        </div>
        <div class="sub-heading" style="color: aliceblue  ;">
          See what others are saying about us
        </div>
      </div>

      <div id="slide-wrapper">
        <div id="horizontal-slide">
          <div class="names">{{activeTestimonials.names}}</div>
          <div class="role">{{activeTestimonials.role}}</div>
          <div class="quote">{{activeTestimonials.testimonials}}</div>
          <div id="fab">
            <button mat-mini-fab color="primary" (click)="testimonialScroll();">
              <span class="material-icons">
                chevron_right
              </span>
            </button>
          </div>
        </div>
        <div id="pagination">
          <span *ngFor="let item of testimonialArr; let i = index" [ngClass]="{'active': testimonial == i}"></span>
        </div>
      </div>
    </div>


  </div>


  <div id="gallery" #gallery>
    <div class="div1 img-wrapper">
      <img src="https://tuteh-storage.s3-eu-west-1.amazonaws.com/assets/IMG-20191218-WA000.jpg" alt="">
    </div>
    <div class="div2 img-wrapper">
      <img src="https://tuteh-storage.s3-eu-west-1.amazonaws.com/assets/IMG-20191218-WA0016.jpg" alt="">
    </div>
    <div class="div3 img-wrapper">
      <img src="https://tuteh-storage.s3-eu-west-1.amazonaws.com/assets/IMG_0239.JPG" alt="">
    </div>
    <div class="div4 img-wrapper">
      <img src="https://tuteh-storage.s3-eu-west-1.amazonaws.com/assets/IMG_0256.JPG" alt="">
    </div>
    <div class="div5 img-wrapper">
      <img src="https://tuteh-storage.s3-eu-west-1.amazonaws.com/assets/IMG_0327.JPG" alt="">
    </div>
    <div class="div6 img-wrapper">
      <img src="https://tuteh-storage.s3-eu-west-1.amazonaws.com/assets/IMG_20191125_123010.jpg" alt="">
    </div>
  </div>

  <div id="faq-main">

    <div class="wrapper">
      <div class="content">
        <div class="heading">
          FAQs
        </div>
        <div class="sub-heading">
          Find answers to commonly asked questions.
        </div>

      </div>

      <div id="faqs">
        <div class="faq" *ngFor="let item of faqs; let i = index" [ngClass]="{'expandFaq': activeFaq == i}">
          <div class="question" (click)="activeFaq == i ? activeFaq = null : activeFaq = i ">
            <span>
              {{item.question}}
            </span>
            <span class="material-icons">
              {{activeFaq == i ? 'north' :'south'}}
            </span>
          </div>
          <div class="answer">
            {{item.answer}}
          </div>
        </div>
      </div>



    </div>

  </div>

  <div id="partners" #partners>
    <div class="wrapper">
      <div class="content">
        <div class="heading">
          Partnerships
        </div>
        <div class="sub-heading">
          Outstanding companies we are working with
        </div>
      </div>
      <div id="companies">
        <a href="http://www.tshwane.gov.za/" class="company"><img
            src="https://tuteh-storage.s3-eu-west-1.amazonaws.com/assets/partners/city_of_tshwane_logo.jpg" alt=""></a>

        <a href="https://www.gsibande.gov.za/" class="company"><img
            src="https://tuteh-storage.s3-eu-west-1.amazonaws.com/assets/partners/Gert-Sibande-District-Municipality.jpg"
            alt=""></a>


        <a href="http://gladafrica.com/" class="company"><img
            src="https://tuteh-storage.s3-eu-west-1.amazonaws.com/assets/partners/Glad-Africa-gauteng.jpg" alt=""></a>


        <a href="https://www.mbombela.gov.za/" class="company"><img
            src="https://tuteh-storage.s3-eu-west-1.amazonaws.com/assets/partners/image jpg logo.jpg" alt=""></a>


        <a href="https://www.optimal.world/" class="company"><img
            src="https://tuteh-storage.s3-eu-west-1.amazonaws.com/assets/partners/Optimal logo.png" alt=""></a>


        <a href="https://www.thensg.gov.za/tps://www.ticeafrica.com/w/upcoming/" class="company"><img
            src="https://tuteh-storage.s3-eu-west-1.amazonaws.com/assets/partners/school of government large.png"
            alt=""></a>


        <a href="https://www.dtps.gov.za/" class="company"><img
            src="https://tuteh-storage.s3-eu-west-1.amazonaws.com/assets/partners/thumb_department_telecommunications_and_postal_services.png"
            alt=""></a>

      </div>
    </div>
  </div>

</main>

<footer>
  <div class="wrapper">
    <div class="col-4">
      <div>
        <h3>Links</h3>
        <span>Home</span>
        <span>Courses</span>
        <span>News</span>
        <span>Contact Us</span>


      </div>
      <div>
        <h3>Address</h3>
        <span>Group Floor</span>
        <span>Building 23, South Wig</span>
        <span>CSIR, Meiring Naude Road</span>
        <span>Brummeria, Pretoria</span>


      </div>

      <div>
        <h3>Contacts</h3>
        <span>enquiries@ticeafrica.com </span>
        <span>012-111-7864</span>
      </div>

      <div id="tut">
        <img src="https://tuteh-storage.s3-eu-west-1.amazonaws.com/assets/tut logo.png" alt="">
      </div>
    </div>
  </div>
  <div class="hr wrapper"></div>
  <div class="wrapper">
    <h4>© 2022 Tshwane Institute for Continuing Education. All Rights Reserved</h4>

    <a
      href="https://tice-storage.s3.eu-west-1.amazonaws.com/POPIA+Policy%5B14274%5D.pdf"
      target="_blank">Privacy Policy</a>

  </div>

</footer>
