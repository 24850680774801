import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ArloService {

  private baseUrl = environment.arloBaseUrl;
  public courses: BehaviorSubject<any>;
  public categories: BehaviorSubject<any>;

  constructor(
    private http: HttpClient
  ) {
    this.courses = new BehaviorSubject(null);
    this.categories = new BehaviorSubject(null);
  }

  getCoursesObj() {
    return this.courses.asObservable();
  }

  getCategoriesObj() {
    return this.categories.asObservable();
  }

  setCategories(categories) {
    this.categories.next(categories)
  }

  setCourses(courses) {
    this.courses.next(courses)
  }

  getCourses() {
    return this.http.get(`${this.baseUrl}/eventtemplates/`, {
      params: new HttpParams().set('format', 'json').set('fields', 'TemplateID,Code,Name,Description,Categories,AdvertisedDuration,AdvertisedPresenters,BestAdvertisedOffers,Tags,Credits,ViewUri,RegisterInterestUri,RegisterPrivateInterestUri,Media')
    }).toPromise();
  }

  getUpcomingCourses(){
    return this.http.get(`${this.baseUrl}/eventsearch/`, {
      params: new HttpParams().set('format', 'json').set('fields', 'EventID,EventTemplateID,Name,Code,Summary,Description,StartDateTime,EndDateTime,StartTimeZoneAbbr,EndTimeZoneAbbr,Sessions,Location,IsFull,PlacesRemaining,Categories,AdvertisedOffers,SessionsDescription,Notice,ViewUri,RegistrationInfo,Provider,Sessions.Name,Sessions.Summary,Sessions.StartDateTime,Sessions.EndDateTime,Sessions.StartTimeZoneAbbr,Sessions.EndTimeZoneAbbr,Sessions.TimeZoneID,Sessions.TimeZone,Sessions.Presenters,Sessions.Location,Sessions.IsFull,Sessions.AdvertisedOffers,Sessions.PlacesRemaining,Sessions.Presenters,Sessions.Tags,Media')
    }).toPromise();

    
  }

  getCategories() {
    return this.http.get(`${this.baseUrl}/eventtemplatecategories`, {
    }).toPromise();
  }
}
