<header #hero>
    
    <!-- Global site tag (gtag.js) - Google Analytics -->

<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-8T3VFGQZZH');
</script>

  <div class="max-wrapper">
    <div id="logo"></div>
    <button id="menu" routerLink="/">
      <span class="material-icons">
        arrow_back
      </span>
    </button>
    <nav>
      <ul>
        <ul>
          <li routerLink="/"><a>HOME</a></li>
        </ul>
      </ul>

    </nav>
  </div>
</header>

<main>
  <div class="wrapper">
    <div id="breadcrumb">
      <span routerLink="/">home</span>
      <span>/</span>
      <span>about us</span>
    </div>
    <div id="story">
      <div class="content">
        <div class="heading">
          About Us
        </div>
        <div class="sub-heading">
          Tshwane Institute for Continuing Education
        </div>
       
      </div>
      <p>
        TICE is a third-stream income contributor for TUT and has been mandated to solicit, coordinate, and manage Short
        Learning Programmes on behalf of and for the benefit of TUT. We assist organization and individuals to transition their competencies and skills into their future of work.
      </p>
    </div>
    <div id="values">

      <div class="content">
        <div class="heading">
          Our Values
        </div>

      </div>
      <div id="cards">
        <div class="card">
          <span class="material-icons">
            emoji_events
          </span>
          <div>excellence</div>
        </div>
        <div class="card">
          <span class="material-icons">
            trip_origin
          </span>
          <div>Collaboration</div>
        </div>
        <div class="card">
          <span class="material-icons">
            brush
          </span>
          <div>Creativity</div>
        </div>
        <div class="card">
          <span class="material-icons">
            schedule
          </span>
          <div>Realibility</div>
        </div>
      </div>

    </div>

    <div id="board">
      <div class="content">
        <div class="heading">
          Board Of Directors
        </div>
      </div>

      <div id="members">
        <div class="member">
          <div class="img">
            <img src="https://tuteh-storage.s3-eu-west-1.amazonaws.com/assets/board/karabo.jpg" alt="">
          </div>
          <div class="details">
            <div class="names">Miss Karabo Mohale</div>
            <small>Chairperson | TICE</small>
            <p>
              Chairperson of TICE Miss Karabo Mohale is an alumni of the Tshwane University of Technology and has served
              in various student structures in the University. She was the first female to be elected to serve as
              President of the Pretoria Campus SRC in 2013/2014. In her tenure as President, she also served as a member
              of the Institutional Forum and Student Services Council. <br><br>

              In 2016 at the tender age of 24, Miss Mohale was elected to serve as a member of the TUT Council
              representing Convocation, giving her great exposure to governance in the Higher Education Sector. In her
              time in Council, she served on various subcommittees including the Employment Conditions Committee and the
              Senior Selection and Appointments Committee of Council. It was during this period where Council focused
              much of its work on transformation, stability in management and financial viability for the sustainability
              of the institution. <br><br>

              Miss Mohale having studied Marketing pursued a career in the Financial Industry, cutting her teeth at one
              of the big commercial Banks. She is currently working in Marketing Distribution in the Insurance Industry,
              where she developed a passion for financial wellness and is skilled in Wealth Management. <br>

              Miss Mohale continues to be a proponent of good governance and greatly passionate about the enhancement of
              women's socioeconomic and political participation in society. She continues to make a contribution in
              society through her activism in the political space.
            </p>
          </div>
        </div>

        <div class="member">
          <div class="img">
            <img src="https://tuteh-storage.s3-eu-west-1.amazonaws.com/assets/board/nick.jpg" alt="">
          </div>
          <div class="details">
            <div class="names">Mr Nicolas Motsatse</div>
            <small>Chief Excutive Officer | TUT Enterprise Holdings</small>
            <p>With the imperative of providing relevant, quality and decolonised teaching and learning environment, the
              Council of TUT recognised the financial limitations the University was facing and resolved to address it
              through the establishment of TUTEH. Having been the Deputy of Council since 2014, Nick was appointed in
              February 2018 as the founding CEO. <br><br>

              Nick’s background spans a number of industries with his most recent being the founder and owner of Moahi
              Mining Solutions, a minerals processing plant in based in Limpopo. Prior to that Nick was involved in the
              establishment of a few startups, a path he followed after he stepped down from his corporate career as an
              Executive and Director of companies.

            </p>
          </div>
        </div>

        <div class="member">
          <div class="img">
            <img src="https://tuteh-storage.s3-eu-west-1.amazonaws.com/assets/board/stanley.jpg" alt="">
          </div>
          <div class="details">
            <div class="names">Prof Stanley Mukhola</div>
            <small>Deputy Vice Chancellor | Teaching, Learning and Technology</small>
            <p>Prof Stanley Mukhola is a Health Scientist from Tshwane University of Technology. He is currently heading
              the academic project as the Deputy Vice Chancellor for Teaching, Learning and Technology. <br><br>

              He has been in academia for 27 years. With his current responsibility of heading the academic project at
              the biggest contact university in South Africa with 66 000 students, he still participates actively in
              research
              activities. He has been invited to speak as keynote speakers at national and international conferences. He
              supervises postgraduate students and published numerous research articles in Health and Education
              disciplines.</p>
          </div>
        </div>


        <div class="member">
          <div class="img">
            <img src="https://tuteh-storage.s3-eu-west-1.amazonaws.com/assets/board/malesela.jpg" alt="">
          </div>
          <div class="details">
            <div class="names">Dr Malesela Motlatla</div>
            <small>Founder | Malesela Holdings Pty Ltd</small>
            <p>A businessperson who has been the head of 7 different companies and occupies the position of Chairman of
              Crossroads Distribution (Pty) Ltd.; Chairman of Office of the Banking Adjudicator and Independent
              Non-Executive Chairman of Finbond Group Ltd. Dr Motlatla is also on the board of Floorworx Africa (Pty)
              Ltd. And Safic Bee (Pty) Ltd. And Lifetime Deputy Governor of American Biographical Institute Research
              Association. <br><br>

              In the past he held the position of Executive Chairman at Malesela Holdings Pty Ltd.; Non-Executive
              Chairman at Accentuate Ltd. And Chairman at Safic (Pty) Ltd. (a subsidiary of Accentuate Ltd.).</p>
          </div>
        </div>



      </div>
    </div>



  </div>

</main>

<footer>
  <div class="wrapper">
    <div class="col-4">
      <div>
        <h3>Links</h3>
        <span>Home</span>
        <span>Courses</span>
        <span>News</span>
        <span>Contact Us</span>


      </div>
      <div>
        <h3>Address</h3>
        <span>Group Floor</span>
        <span>Building 23, South Wing</span>
        <span>CSIR, Meiring Naude Road</span>
        <span>Brummeria, Pretoria</span>


      </div>

      <div>
        <h3>Contacts</h3>
        <span>enquiries@ticeafrica.com </span>
        <span>012-111-7864</span>
      </div>

      <div id="tut">
        <img src="https://tuteh-storage.s3-eu-west-1.amazonaws.com/assets/tut logo.png" alt="">
      </div>
    </div>
  </div>
  <div class="hr wrapper"></div>
  <div class="wrapper">
    <h4>© 2022 Tshwane Institute for Continuing Education. All Rights Reserved</h4>
  </div>
  
  <div class="wrapper">
    <a href="https://tice-storage.s3.eu-west-1.amazonaws.com/POPIA+Policy%5B14274%5D.pdf">Privacy Policy</a>
  
  </div>

</footer>
